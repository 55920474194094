import { mapActions, mapGetters } from "vuex";
import router from "@/router";

export default {
    name: "quiz",
    data() {
        return {
            answers: [],
        };
    },
    components: {},
    computed: {
        ...mapGetters({
            quizInfo: "courses/quizInfo",
            quizResultInfo: "courses/quizResultInfo",
        }),
    },
    watch: {
        answers: {
            deep: true,
            handler: function() {
                this.answers.forEach(answer => {
                    return (this.$refs["inputRef_" + answer.question_id][0].innerHTML = "");
                });
            },
        },
    },
    created() {
        this.getQuestions({
            courseThemeId: this.$route.params.id,
            courseId: this.$route.query.id,
            quizToken: this.$route.query.quiz_token,
        }).then(() => {
            this.quizInfo.questions.forEach(question => {
                let obj = {};
                obj.question_id = question.id;
                obj.type = question.type;
                if (question.type !== "open") {
                    obj.options = [];
                } else {
                    obj.content = "";
                }
                this.answers.push(obj);
            });
        });
    },
    methods: {
        ...mapActions({
            getQuestions: "courses/GET_QUIZ_QUESTIONS",
            sendAnswers: "courses/SEND_QUIZ_ANSWERS",
        }),
        getRef(id) {
            return `inputRef_${id}`;
        },
        stopQuiz() {
            let validData = [];
            this.answers.forEach(answer => {
                if (answer.type === "single") {
                    if (answer.options.length) {
                        validData.push(answer.id);
                    } else {
                        this.$refs["inputRef_" + answer.question_id][0].innerHTML = "Вкажіть правильну відповідь";
                    }
                }
                if (answer.type === "multiple") {
                    if (answer.options.length) {
                        validData.push(answer.id);
                    } else {
                        this.$refs["inputRef_" + answer.question_id][0].innerHTML =
                            "Вкажіть одну або декілька правильних відповідей";
                    }
                }
                if (answer.type === "open") {
                    if (answer.content.length) {
                        validData.push(answer.id);
                    } else {
                        this.$refs["inputRef_" + answer.question_id][0].innerHTML = "Напишіть відповідь на питання";
                    }
                }
            });
            if (validData.length === this.answers.length) {
                this.sendAnswers({
                    courseThemeId: this.$route.params.id,
                    courseId: this.$route.query.id,
                    quiz_token: this.$route.query.quiz_token,
                    answers: this.answers,
                }).then(() => {
                    this.$toasted.success(this.quizResultInfo.message);
                    router.push({ name: "quiz-result", params: { id: this.$route.params.id } });
                });
            }
        },
    },
};
